import { ReactNode, useState } from "react";
import * as c from "./Alert.module.css";
import { IoCloseCircle } from "react-icons/io5";

type AlertProps = {
  severity: "success" | "warning" | "danger" | "info";
  dismissible?: boolean;
  onClose?: () => void;
  children: ReactNode;
  className?: string;
};

const Alert = ({ severity, dismissible = true, onClose, children, className }: AlertProps) => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  const handleDismiss = () => {
    setIsVisible(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className={`${c.alert} ${c[severity]}`}>
      <span className={c["alert-span"]}>{children}</span>
      {dismissible && (
        <div className={c["close-button-wrapper"]}>
          <button onClick={handleDismiss} className={c["close-button"]}>
            <IoCloseCircle />
          </button>
        </div>
      )}
    </div>
  );
};

export default Alert;
