import { useCallback, useEffect, useRef, useState } from "react";
import { AxiosError } from "axios";
import { getOffers, getTransactions } from "api/offers";
import { useErrorHandler } from "contexts/errorContext";
import { getCachedConfiguration, setCacheConfiguration } from "utils/functions/configurationUtil";
import { getPanelistConfiguration, setPanelistState } from "api/panelist";
import { Offer, OfferStatuses } from "interfaces/Offer";
import { PanelistConfig } from "interfaces/PanelistConfig";
import { PanelistState } from "interfaces/PanelistState";
import OfferCardItem from "../../components/offers/OfferCardItem";
import MyOfferCardItem from "components/offers/MyOfferCardItem";
import Modal from "../../components/ui/Modal";
import QrModal from "components/ui/QrModal";
import Spinner from "components/ui/Spinner";
import Alert from "components/ui/Alert";
import OfferModalContent from "../../components/offers/OfferModal/OfferModalContent";
import RewardModalContent from "components/rewards/RewardModal/RewardModalContent";
import OfferFilter from "components/offers/OfferFilter";
import OfferDeviceSelector from "components/offers/OfferDeviceSelector";
import MainFooter from "pages/SurveyWall/MainFooter/MainFooter";
import { MdWindow } from "react-icons/md";
import * as c from "./OffersPage.module.css";

function OffersPage() {
  const handleError = useErrorHandler();
  const [modalOpen, setModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [offers, setOffers] = useState<any>([]);
  const [myOffers, setMyOffers] = useState<any>([]);
  const [showEmptyMyOffers, setShowEmptyMyOffers] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingOffers, setIsUpdatingOffers] = useState(false);
  const [activeFilters, setActiveFilters] = useState<any>([]);
  const [activeTab, setActiveTab] = useState("all");
  const [isThresholdReached, setIsThresholdReached] = useState(false);
  const [modalType, setModalType] = useState<"offer" | "reward" | null>(null);
  const [modalContent, setModalContent] = useState<{
    attemptedAt?: string;
    goals?: Offer["goals"];
    isCompleted?: boolean;
    description?: string[];
    platforms: string[];
    operatingSystems?: string[];
    title: string;
    rewardIac: number;
    thumbnailUrl?: string;
    heroImageUrl?: string;
    offerId?: number;
    offerStatus?: OfferStatuses;
  }>({
    platforms: [],
    title: "",
    rewardIac: 0,
  });
  const [showOfferOnboardingWidget, setShowOfferOnboardingWidget] = useState(false);
  const configuration = getCachedConfiguration();
  const activeTabRef = useRef(activeTab);
  const isFetchingRef = useRef(false);

  const getModifiedGoals = (offer: Offer) => {
    let foundCompleted = false;

    const modifiedGoals = offer.goals.map((goal, index) => {
      if (index === 0 && !goal.isCompleted) {
        return { ...goal, isPending: true };
      }
      if (index === 1 && !offer.goals[0].isCompleted) {
        return { ...goal, isPending: true };
      }

      if (foundCompleted && !goal.isCompleted) {
        foundCompleted = false;
        return { ...goal, isPending: true };
      }

      if (goal.isCompleted) {
        foundCompleted = true;
      }

      if (offer.goals.every((g) => g.isCompleted)) {
        return { ...goal, isPending: false };
      }

      return goal;
    });

    return { ...offer, goals: modifiedGoals };
  };

  const openModal = (offer: Offer, type: "offer" | "reward") => {
    document.body.style.overflow = "hidden";
    if (type === "reward") {
      offer = getModifiedGoals(offer);
    }

    const content =
      type === "offer"
        ? {
            goals: offer.goals,
            description: offer.description,
            platforms: offer.platforms,
            operatingSystems: offer.operatingSystems,
            title: offer.title,
            rewardIac: offer.rewardIac,
            thumbnailUrl: offer.thumbnailUrl,
            heroImageUrl: offer.heroImageUrl,
            offerId: offer.id,
          }
        : {
            attemptedAt: offer.attemptedAt,
            goals: offer.goals,
            isCompleted: offer.isCompleted,
            description: offer.description,
            platforms: offer.platforms,
            operatingSystems: offer.operatingSystems,
            title: offer.title,
            rewardIac: offer.rewardIac,
            thumbnailUrl: offer.thumbnailUrl,
            heroImageUrl: offer.heroImageUrl,
            offerId: offer.id,
            offerStatus: offer.status,
          };

    setModalContent(content);

    setModalType(type);
    setModalOpen(true);
  };
  const closeModal = () => {
    document.body.style.overflow = "";
    setModalOpen(false);
    setModalType(null);
  };

  const closeQrModal = () => {
    document.body.style.overflow = "";
    setQrModalOpen(false);
    if (modalType) {
      setModalOpen(true);
    }
  };

  const openQrModal = (qrCode: string) => {
    setQrCode(qrCode);

    setModalOpen(false);
    setQrModalOpen(true);
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const LIMIT = configuration!.offersLimit ?? 30;

  const loadMyOffers = () => {
    setIsLoading(true);
    getTransactions(configuration!.hashed_publisher_app_uid!)
      .then((response) => {
        setIsLoading(false);
        setMyOffers(response);
        setShowEmptyMyOffers(true);
      })
      .catch((error) => {
        handleError(error as AxiosError);
      });
  };

  const loadOffers = useCallback(
    async (reset = false) => {
      if (!reset && !hasMore) return;

      setIsLoading(true);
      const currentOffset = reset ? 0 : offset;
      const filters = reset ? activeFilters : activeFilters;

      try {
        const newOffers = await getOffers(
          configuration!.hashed_publisher_app_uid!,
          detectMobileOS(),
          currentOffset,
          LIMIT,
          filters,
        );

        if (reset) {
          setOffers(newOffers);
        } else {
          setOffers((prevOffers: any) => [...prevOffers, ...newOffers]);
        }

        if (configuration!.offersLimit !== null) {
          setHasMore(false);
        } else if (newOffers.length < LIMIT) {
          setHasMore(false);
        }
        if (configuration!.offersLimit === null) {
          setOffset((prevOffset) => (reset ? LIMIT : prevOffset + LIMIT));
        }
      } catch (error) {
        handleError(error as AxiosError);
      } finally {
        setIsLoading(false);
      }
    },
    [offset, hasMore, isLoading, activeFilters],
  );

  const resetOffsetLoadOffers = async () => {
    setOffers([]);
    setHasMore(true);
    setOffset(0);
    await loadOffers(true);
  };

  const handleScroll = useCallback(() => {
    if (activeTabRef.current !== "all") return;

    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;

    if (
      scrollHeight - scrollTop <= clientHeight + 100 &&
      !isLoading &&
      hasMore &&
      !isFetchingRef.current
    ) {
      isFetchingRef.current = true;

      loadOffers().finally(() => {
        isFetchingRef.current = false;
      });
    }
  }, [isLoading, hasMore, loadOffers]);

  const setFilters = (filters: string[]) => setActiveFilters(filters);

  useEffect(() => {
    activeTabRef.current = activeTab;
  }, [activeTab]);

  useEffect(() => {
    const resetOffersOnFilterChange = async () => {
      setOffers([]);
      setHasMore(true);
      setOffset(0);
      await loadOffers(true);
    };

    resetOffersOnFilterChange();
  }, [activeFilters]);

  useEffect(() => {
    setActiveFilters(configuration!.offerDesktopDeviceTypesFilter);
    setShowOfferOnboardingWidget(
      configuration!.is_first_offer_attempted && !configuration!.is_offer_onboarding_widget_closed,
    );
  }, []);

  useEffect(() => {
    if (isLoading) {
      document.body.classList.add(c["no-scroll"]);
    } else {
      document.body.classList.remove(c["no-scroll"]);
    }
  }, [isLoading]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, activeTab, hasMore, offset]);

  const detectMobileOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "iOS";
    if (/android/i.test(userAgent)) return "Android";
    return "";
  };

  const handleScrollThreshold = (treshold: boolean) => {
    setIsThresholdReached(treshold);
  };

  const closeOfferOnboardingWidget = () => {
    setShowOfferOnboardingWidget(false);

    setPanelistState(configuration!.hashed_publisher_app_uid ?? "", {
      isOfferOnboardingWidgetClosed: true,
    })
      .then(({ isFirstOfferAttempted, isOfferOnboardingWidgetClosed }: PanelistState) => {
        configuration!.is_first_offer_attempted = isFirstOfferAttempted;
        configuration!.is_offer_onboarding_widget_closed = isOfferOnboardingWidgetClosed;

        setCacheConfiguration(configuration!);
      })
      .catch((error) => {
        console.error("Error updateing panelist state:", error);
        handleError(error);
      });
  };

  const handleOfferAttempt = () => {
    if (configuration!.is_first_offer_attempted) {
      return;
    }

    getPanelistConfiguration(
      configuration?.hashed_publisher_app_uid ?? "",
      configuration?.publisher_app_uid ?? "",
      configuration?.device_id ?? "",
      configuration?.session_uid ?? "",
      configuration?.language,
    )
      .then(({ isFirstOfferAttempted, isOfferOnboardingWidgetClosed }: PanelistConfig) => {
        configuration!.is_first_offer_attempted = isFirstOfferAttempted;
        configuration!.is_offer_onboarding_widget_closed = isOfferOnboardingWidgetClosed;

        setCacheConfiguration(configuration!);
        setShowOfferOnboardingWidget(
          configuration!.is_first_offer_attempted &&
            !configuration!.is_offer_onboarding_widget_closed,
        );
      })
      .catch((error) => {
        console.error("Error fetching user configuration:", error);
        handleError(error);
      });
  };

  return (
    <>
      {isLoading && (
        <div className={c["spinner-overlay"]}>
          <Spinner size="normal" />
        </div>
      )}
      {!isLoading && showOfferOnboardingWidget && (
        <div className={c["offers-alert-container"]}>
          <Alert severity="info" dismissible={true} onClose={closeOfferOnboardingWidget}>
            Great job! You started your first offer. Offer rewards may not be instant, if you
            completed the offer it may take up to 7 days before you are credited.
          </Alert>
        </div>
      )}
      <div className={c["offers-page"]}>
        <div className={c["offers-page-content-wrapper"]}>
          {detectMobileOS() === "" && activeFilters && activeFilters.length > 0 && (
            <div className={c["offers-filter-container"]}>
              <OfferFilter
                onDeviceSelection={setFilters}
                onStartSpinner={() => setIsLoading(true)}
                disabled={activeTab !== "all"}
              />
            </div>
          )}
          {detectMobileOS() === "" &&
            !isUpdatingOffers &&
            activeFilters &&
            activeFilters.length == 0 && (
              <div className={c["offers-device-selector-container"]}>
                <OfferDeviceSelector
                  onDeviceSelection={setFilters}
                  setIsUpdatingOffers={setIsUpdatingOffers}
                  onStartSpinner={() => setIsLoading(true)}
                />
              </div>
            )}
          <div className={c["offers-tabs-wrapper"]}>
            <div className={c["offers-tabs"]}>
              <button
                className={`${c["tab-button"]} ${activeTab === "all" ? c["active"] : ""}`}
                onClick={() => {
                  handleTabChange("all");
                  if (activeTab !== "all") {
                    resetOffsetLoadOffers();
                  }
                }}
              >
                Available Offers
              </button>
              <button
                className={`${c["tab-button"]} ${activeTab === "my-offers" ? c["active"] : ""}`}
                onClick={() => {
                  handleTabChange("my-offers");
                  if (activeTab !== "my-offers") {
                    loadMyOffers();
                  }
                }}
              >
                My Offers
              </button>
            </div>
          </div>
          {activeTab === "all" && (
            <div className={`${c["offers-container"]} ${offers.length == 0 ? c["no-offers"] : ""}`}>
              {offers && offers.length > 0
                ? offers.map((offer: Offer) => (
                    <OfferCardItem
                      key={offer.id}
                      onModalOpen={() => openModal(offer, "offer")}
                      offer={offer}
                      currencyName={configuration!.currency_name!}
                    />
                  ))
                : !isUpdatingOffers &&
                  !isLoading && <p className={c["no-offers-message"]}>No Offers Available</p>}
            </div>
          )}
          {activeTab === "my-offers" && (
            <div className={c["my-offers-container"]}>
              {myOffers.length === 0 && showEmptyMyOffers && (
                <div className={c["empty-my-offers-container"]}>
                  <div className={c["empty-my-offers-icon-wrapper"]}>
                    <MdWindow />
                  </div>
                  <div className={c["empty-my-offers-text-wrapper"]}>
                    <span className={c["main-text"]}>All in-progress offers will show here!</span>
                    <span className={c["sub-text"]}>Start an offer in the “All Offers” tab!</span>
                  </div>
                </div>
              )}
              {myOffers.length != 0 && (
                <div className={c["filled-my-offers-container"]}>
                  <span className={c["my-offers-section"]}>In-Progress Offers</span>
                  <div className={c["my-offers-wrapper"]}>
                    {myOffers
                      .filter((offer: any) => !offer.isCompleted)
                      .map((offer: any) => (
                        <MyOfferCardItem
                          key={offer.id}
                          onModalOpen={() => openModal(offer, "reward")}
                          offer={offer}
                          currencyName={configuration!.currency_name!}
                        />
                      ))}
                  </div>

                  <span className={c["my-offers-section"]}>Completed Offers</span>
                  <div className={c["my-offers-wrapper"]}>
                    {myOffers.filter((offer: any) => offer.isCompleted).length > 0 ? (
                      myOffers
                        .filter((offer: any) => offer.isCompleted)
                        .map((offer: any) => (
                          <MyOfferCardItem
                            key={offer.id}
                            onModalOpen={() => openModal(offer, "reward")}
                            offer={offer}
                            currencyName={configuration!.currency_name!}
                          />
                        ))
                    ) : (
                      <span className={c["no-completed-offer"]}>
                        Complete all goals to see completed offers here!
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <MainFooter style={{ marginTop: 0 }} />
      </div>
      <Modal
        show={modalOpen}
        onClose={closeModal}
        treshold={isThresholdReached}
        heroImage={modalContent.heroImageUrl}
      >
        {modalType === "offer" && (
          <OfferModalContent
            modalContent={modalContent}
            onOpenQrModal={openQrModal}
            onOfferAttempt={handleOfferAttempt}
            onScrollTreshold={handleScrollThreshold}
          />
        )}
        {modalType === "reward" && (
          <RewardModalContent
            modalContent={modalContent}
            onOpenQrModal={openQrModal}
            onScrollTreshold={handleScrollThreshold}
          />
        )}
      </Modal>
      <QrModal show={qrModalOpen} qrCode={qrCode} onClose={closeQrModal} />
    </>
  );
}

export default OffersPage;
