import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useHeaderTabContext } from "contexts/tabContext";
import { useColorContext } from "contexts/colorContext";
import { changeOpacity } from "utils/functions/colorUtil";
import OffersTabIcon from "./OffersTabIcon";
import RewardsTabIcon from "./RewardsTabIcon";
import SurveysTabIcon from "./SurveysTabIcon";
import * as c from "./MainHeader.module.css";
import { getCachedConfiguration } from "utils/functions/configurationUtil";
import { useTranslation } from "react-i18next";

enum Tabs {
  Surveys,
  Offers,
  Rewards,
}

function MainHeader() {
  const { t } = useTranslation();
  const { selectedHeaderTab, setSelectedHeaderTab } = useHeaderTabContext();
  const { primaryColorCtx } = useColorContext();
  const navigate = useNavigate();
  const [showLogo, setShowLogo] = useState(false);
  var configuration = getCachedConfiguration();

  /* Hardcoded until Austria and Finland support is added to the backend */
  var surveysEnabled =
    configuration?.surveys_enabled &&
    configuration?.language != "en-at" &&
    configuration?.language != "en-fi";
  var offersEnabled = configuration?.offers_enabled && configuration?.isLocaleSupportedForOffers;

  const logoUrl = configuration?.logo_url;

  useEffect(() => {
    if (location.pathname.includes("surveys")) {
      setSelectedHeaderTab(Tabs.Surveys);
    } else if (location.pathname.includes("offers")) {
      setSelectedHeaderTab(Tabs.Offers);
    } else if (location.pathname.includes("rewards")) {
      setSelectedHeaderTab(Tabs.Rewards);
    }
  }, []);

  const onTabClick = (selectedTab: Tabs) => {
    switch (selectedTab) {
      case Tabs.Surveys:
        navigate("/surveys");
        break;
      case Tabs.Offers:
        navigate("/offers");
        break;
      case Tabs.Rewards:
        navigate("/rewards");
        break;

      default:
        break;
    }
    setSelectedHeaderTab(selectedTab);
  };

  function Offers() {
    return (
      <div
        onClick={() => onTabClick(Tabs.Offers)}
        className={`${c["header-tab"]} ${selectedHeaderTab === Tabs.Offers ? c["selected"] : ""}`}
      >
        <OffersTabIcon color={selectedHeaderTab === Tabs.Offers ? primaryColorCtx : "#ffffff"} />
        {t("MENU_NAVIGATION_ITEMS.OFFERS")}
      </div>
    );
  }

  function Surveys() {
    return (
      <div
        onClick={() => onTabClick(Tabs.Surveys)}
        className={`${c["header-tab"]} ${selectedHeaderTab === Tabs.Surveys ? c["selected"] : ""}`}
      >
        <SurveysTabIcon color={selectedHeaderTab === Tabs.Surveys ? primaryColorCtx : "#ffffff"} />
        {t("MENU_NAVIGATION_ITEMS.SURVEYS")}
      </div>
    );
  }

  function Rewards() {
    return (
      <div
        onClick={() => onTabClick(Tabs.Rewards)}
        className={`${c["header-tab"]} ${selectedHeaderTab === Tabs.Rewards ? c["selected"] : ""}`}
      >
        <RewardsTabIcon color={selectedHeaderTab === Tabs.Rewards ? primaryColorCtx : "#ffffff"} />
        {t("MENU_NAVIGATION_ITEMS.REWARDS")}
      </div>
    );
  }

  function renderSurveyOfferTabs() {
    return (
      <>
        {surveysEnabled && Surveys()}
        {offersEnabled && Offers()}
      </>
    );
  }

  return (
    <div
      className={c["header-container"]}
      style={{
        background: `linear-gradient(92deg, ${primaryColorCtx} 0.07%, ${changeOpacity(
          primaryColorCtx,
          0.95,
        )} 96.71%)`,
      }}
    >
      <div className={`${c["header"]} ${!showLogo ? c["centered-header"] : ""}`}>
        <div className={c["header-tab-container"]}>
          {renderSurveyOfferTabs()}
          {Rewards()}
        </div>
        <img
          className={`${c["header-logo"]} ${!showLogo ? c["hidden"] : ""}`}
          src={logoUrl}
          onLoad={() => setShowLogo(true)}
        />
      </div>
      <div className={`${c["decorative-circle"]} ${c["circle-1"]}`}></div>
      <div className={`${c["decorative-circle"]} ${c["circle-2"]}`}></div>
      <div className={`${c["decorative-circle"]} ${c["circle-3"]}`}></div>
    </div>
  );
}

export default MainHeader;
