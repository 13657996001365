import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { getSummerizedTransactions, getSurveys, getTransactions } from "api/offers";
import { useErrorHandler } from "contexts/errorContext";
import { getCachedConfiguration } from "utils/functions/configurationUtil";
import { transformCurrency } from "utils/functions/currencyModUtil";
import { Offer, OfferStatuses } from "interfaces/Offer";
import { Reward } from "interfaces/reward";
import MainFooter from "pages/SurveyWall/MainFooter/MainFooter";
import Modal from "components/ui/Modal";
import QrModal from "components/ui/QrModal";
import RewardModalContent from "components/rewards/RewardModal/RewardModalContent";
import MyOfferCardItem from "components/offers/MyOfferCardItem";
import RewardSurveyCardItem from "components/rewards/RewardSurveyCardItem";
import Spinner from "components/ui/Spinner";
import { MdWindow } from "react-icons/md";
import * as c from "./RewardsPage.module.css";

function RewardsPage() {
  const handleError = useErrorHandler();
  const [rewardsOffer, setRewardsOffer] = useState<any>([]);
  const [rewardsSurvey, setRewardsSurvey] = useState<any>([]);
  const [sumAmountsToday, setSumAmountsToday] = useState<number>(0);
  const [sumAmounts30, setSumAmounts30] = useState<number>(0);
  const [showEmptyMyOffers, setShowEmptyRewardOffers] = useState(false);
  const [isThresholdReached, setIsThresholdReached] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [modalContent, setModalContent] = useState<{
    attemptedAt?: string;
    goals?: Offer["goals"];
    isCompleted?: boolean;
    description?: string[];
    platforms: string[];
    operatingSystems?: string[];
    title: string;
    rewardIac: number;
    thumbnailUrl?: string;
    heroImageUrl?: string;
    offerId?: number;
    offerStatus?: OfferStatuses;
  }>({ platforms: [], title: "", rewardIac: 0 });

  const configuration = getCachedConfiguration();

  /* Hardcoded until Austria and Finland support is added to the backend */
  var surveysEnabled =
    configuration?.surveys_enabled &&
    configuration?.language != "en-at" &&
    configuration?.language != "en-fi";
  var offersEnabled = configuration?.offers_enabled && configuration?.isLocaleSupportedForOffers;

  const initialTab = surveysEnabled ? "Surveys" : offersEnabled ? "Offers" : "Surveys";

  const [activeTab, setActiveTab] = useState(initialTab);
  const isSingleTab = (surveysEnabled && !offersEnabled) || (!surveysEnabled && offersEnabled);

  const getModifiedGoals = (offer: Offer) => {
    let foundCompleted = false;

    const modifiedGoals = offer.goals.map((goal, index) => {
      if (index === 0 && !goal.isCompleted) {
        return { ...goal, isPending: true };
      }
      if (index === 1 && !offer.goals[0].isCompleted) {
        return { ...goal, isPending: true };
      }

      if (foundCompleted && !goal.isCompleted) {
        foundCompleted = false;
        return { ...goal, isPending: true };
      }

      if (goal.isCompleted) {
        foundCompleted = true;
      }

      if (offer.goals.every((g) => g.isCompleted)) {
        return { ...goal, isPending: false };
      }

      return goal;
    });

    return { ...offer, goals: modifiedGoals };
  };

  const openModal = (offer: Offer) => {
    document.body.style.overflow = "hidden";
    offer = getModifiedGoals(offer);
    setModalContent({
      attemptedAt: offer.attemptedAt,
      goals: offer.goals,
      isCompleted: offer.isCompleted,
      description: offer.description,
      platforms: offer.platforms,
      operatingSystems: offer.operatingSystems,
      title: offer.title,
      rewardIac: offer.rewardIac,
      thumbnailUrl: offer.thumbnailUrl,
      heroImageUrl: offer.heroImageUrl,
      offerId: offer.id,
      offerStatus: offer.status,
    });
    setModalOpen(true);
  };
  const closeModal = () => {
    document.body.style.overflow = "";
    setModalOpen(false);
  };

  const closeQrModal = () => {
    document.body.style.overflow = "";
    setQrModalOpen(false);
    setModalOpen(true);
  };

  const openQrModal = (qrCode: string) => {
    setQrCode(qrCode);

    setModalOpen(false);
    setQrModalOpen(true);
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const currency = (name: string, amount: number) => {
    return transformCurrency(name, amount);
  };

  useEffect(() => {
    getSummerizedTransactions(configuration!.hashed_publisher_app_uid!, configuration!.device_id!)
      .then((sumAmounts) => {
        setSumAmountsToday(sumAmounts.currentDayTotalAmount);
        setSumAmounts30(sumAmounts.last30DaysTotalAmount);
      })
      .catch((error) => {
        handleError(error);
      });
    if (surveysEnabled) {
      loadSurveys();
    } else if (offersEnabled && !surveysEnabled) {
      loadMyOffers();
    } else {
      setIsLoading(false);
    }
  }, []);

  const loadSurveys = () => {
    setIsLoading(true);
    getSurveys(configuration!.hashed_publisher_app_uid!, configuration!.device_id!)
      .then((surveys) => {
        setRewardsSurvey(surveys);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadMyOffers = () => {
    setIsLoading(true);
    getTransactions(configuration!.hashed_publisher_app_uid!)
      .then((response) => {
        setRewardsOffer(response);
        setShowEmptyRewardOffers(true);
      })
      .catch((error) => {
        handleError(error as AxiosError);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isLoading) {
      document.body.classList.add(c["no-scroll"]);
    } else {
      document.body.classList.remove(c["no-scroll"]);
    }
  }, [isLoading]);

  const detectMobileOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "iOS";
    if (/android/i.test(userAgent)) return "Android";
    return "";
  };

  const handleScrollThreshold = (treshold: boolean) => {
    setIsThresholdReached(treshold);
  };

  return (
    <>
      {isLoading && (
        <div className={c["spinner-overlay"]}>
          <Spinner size="normal" />
        </div>
      )}
      <div className={c["reward-stats"]}>
        <div className={c["stats"]}>
          <div>
            <span className={c["stats-label"]}>Today</span>
            <span className={c["stats-value"]}>
              {sumAmountsToday && currency(configuration!.currency_name!, sumAmountsToday)}
            </span>
          </div>
          <div className={c["divider"]}></div>
          <div>
            <span className={c["stats-label"]}>Last 30 Days</span>
            <span className={c["stats-value"]}>
              {sumAmounts30 && currency(configuration!.currency_name!, sumAmounts30)}
            </span>
          </div>
        </div>
      </div>
      <div className={c["rewards-page"]}>
        <div className={c["rewards-page-content-wrapper"]}>
          {surveysEnabled && offersEnabled && (
            <div className={`${c["offers-tabs-wrapper"]} ${isSingleTab ? c["single"] : ""}`}>
              <div className={c["offers-tabs"]}>
                {surveysEnabled && (
                  <button
                    className={`${c["tab-button"]} ${activeTab === "Surveys" ? c["active"] : ""}`}
                    onClick={() => {
                      handleTabChange("Surveys");
                      if (activeTab !== "Surveys") {
                        loadSurveys();
                      }
                    }}
                  >
                    Surveys
                  </button>
                )}
                {offersEnabled && (
                  <button
                    className={`${c["tab-button"]} ${activeTab === "Offers" ? c["active"] : ""}`}
                    onClick={() => {
                      handleTabChange("Offers");
                      if (activeTab !== "Offers") {
                        loadMyOffers();
                      }
                    }}
                  >
                    Offers
                  </button>
                )}
              </div>
            </div>
          )}
          {activeTab === "Surveys" && (
            <div className={c["rewards-container"]}>
              {rewardsSurvey && rewardsSurvey.length > 0 ? (
                rewardsSurvey.map((reward: Reward, index: number) => (
                  <RewardSurveyCardItem
                    key={index}
                    reward={reward}
                    currencyName={configuration!.currency_name!}
                  />
                ))
              ) : (
                <p className={c["no-content-message"]}>No Survey Rewards</p>
              )}
            </div>
          )}
          {activeTab === "Offers" && (
            <div className={c["my-offers-container"]}>
              {rewardsOffer.length === 0 && showEmptyMyOffers && (
                <div className={c["empty-my-offers-container"]}>
                  <div className={c["empty-my-offers-icon-wrapper"]}>
                    <MdWindow />
                  </div>
                  <div className={c["empty-my-offers-text-wrapper"]}>
                    <span className={c["main-text"]}>All in-progress offers will show here!</span>
                    <span className={c["sub-text"]}>Start an offer in the “All Offers” tab!</span>
                  </div>
                </div>
              )}
              {rewardsOffer.length > 0 && (
                <div className={c["filled-my-offers-container"]}>
                  <span className={c["my-offers-section"]}>In-Progress Offers</span>
                  <div className={c["my-offers-wrapper"]}>
                    {rewardsOffer
                      .filter((offer: any) => !offer.isCompleted)
                      .map((offer: any) => (
                        <MyOfferCardItem
                          key={offer.id}
                          onModalOpen={() => openModal(offer)}
                          offer={offer}
                          currencyName={configuration!.currency_name!}
                        />
                      ))}
                  </div>

                  <span className={c["my-offers-section"]}>Completed Offers</span>
                  <div className={c["my-offers-wrapper"]}>
                    {rewardsOffer.filter((offer: any) => offer.isCompleted).length > 0 ? (
                      rewardsOffer
                        .filter((offer: any) => offer.isCompleted)
                        .map((offer: any) => (
                          <MyOfferCardItem
                            key={offer.id}
                            onModalOpen={() => openModal(offer)}
                            offer={offer}
                            currencyName={configuration!.currency_name!}
                          />
                        ))
                    ) : (
                      <span className={c["no-completed-offer"]}>
                        Complete all goals to see completed offers here!
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <MainFooter style={{}} />
      </div>
      <Modal
        show={modalOpen}
        onClose={closeModal}
        treshold={isThresholdReached}
        heroImage={modalContent.heroImageUrl}
      >
        <RewardModalContent
          modalContent={modalContent}
          onOpenQrModal={openQrModal}
          onScrollTreshold={handleScrollThreshold}
        />
      </Modal>
      <QrModal show={qrModalOpen} qrCode={qrCode} onClose={closeQrModal} />
    </>
  );
}

export default RewardsPage;
