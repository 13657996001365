import { ReactNode, useEffect, useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import * as c from "./Modal.module.css";

interface OfferModalProps {
  show: boolean;
  onClose: () => void;
  children: ReactNode;
  treshold: boolean;
  heroImage: any;
}

const OfferModal = ({ show, onClose, treshold, heroImage, children }: OfferModalProps) => {
  const detectMobileOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "iOS";
    if (/android/i.test(userAgent)) return "Android";
    return "";
  };

  const svgStyle = {
    color: heroImage ? (treshold ? "black" : "white") : "black",
  };

  const buttonStyle = {
    background: heroImage ? (treshold ? "none" : "rgba(0, 0, 0, 0.5)") : "none",
  };

  useEffect(() => {
    if (show) {
      document.documentElement.classList.add(c["no-scroll"]);
    } else {
      document.documentElement.classList.remove(c["no-scroll"]);
    }
  }, [show]);

  if (!show) return null;

  return (
    <>
      {/* <div className={c.backdrop} onClick={onClose}></div> */}
      <div
        className={`${c["modal-overlay"]} ${detectMobileOS() === "iOS" && c["modal-overlay-ios"]}`}
      >
        <div className={c.modal}>
          <button className={c["close-button"]} onClick={onClose} style={buttonStyle}>
            <IoCloseCircle style={svgStyle} />
          </button>
          {children}
        </div>
      </div>
    </>
  );
};

export default OfferModal;
